import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Slider from "react-slick";

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        display: "block",
        padding: "0",
        right: "-55px",
        transform: "translateY(-50%)",
      }}
      className="h-12 w-12"
      onClick={onClick}
    >
      <button className="bg-white rounded-full shadow-md h-12 w-12 text-2xl text-gladior-black hover:text-gladior-pink focus:text-gladior-pink -mr-6 focus:outline-none focus:ring">
        <span className="block" style={{ transform: "scale(1)" }}>
          ➜
        </span>
      </button>
    </div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        display: "block",
        padding: "0",
        left: "-55px",
        transform: "translateY(-50%)",
      }}
      className="h-12 w-12"
      onClick={onClick}
    >
      <button className="bg-white rounded-full shadow-md h-12 w-12 text-2xl text-gladior-black hover:text-gladior-pink focus:text-gladior-pink -mr-6 focus:outline-none focus:ring">
        <span className="block" style={{ transform: "scale(-1)" }}>
          ➜
        </span>
      </button>
    </div>
  );
}

function CoverBar({}) {
  const data = useStaticQuery(
    graphql`
      query {
        allWebcasesYaml {
          edges {
            node {
              id
              subtitle
              title
              samenvatting_overview
              fields {
                slug
              }
              cover_image {
                childImageSharp {
                  fixed(height: 256) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const { allWebcasesYaml } = data;

  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <section className="my-12  px-0 md:px-8 container mx-auto">
        <h2
          id="cases"
          className="mb-10 text-center text-3xl sm:text-4xl md:text-5xl xl:text-4xl leading-tight  text-gladior-black font-bold font-display font-display "
        >
          Our best work
        </h2>

        <div
          id="coverbar"
          style={{ overflow: "hidden" }}
          className="px-2 md:px-10 pb-10 "
        >
          <Slider {...settings}>
            {allWebcasesYaml.edges.map((edge) => {
              let coverObj = edge.node;

              return (
                <div key={coverObj.id}>
                  <div className="grid__link  flex flex-col items-center">
                    <h4 className="text-2xl text-center text-black font-semibold font-display">
                      {coverObj.id}
                    </h4>
                    <p className="text-sm font-semibold text-gray-700 uppercase tracking-wider mb-8 font-display">
                      Case
                    </p>
                    <div className=" img-wrap img-wrap--grid h-64 w-64 mb-4  bg-white">
                      <div className="relative w-full h-full bg-white">
                        <Img
                          style={{ height: "256px", width: "256px" }}
                          className="img img--grid"
                          fixed={coverObj.cover_image.childImageSharp.fixed}
                        />
                      </div>
                    </div>

                    <div className="text-white flex flex-col justify-center content-center items-center text-center mt-8 ">
                      <Link
                        className="bg-transparent hover:bg-gladior-pink  text-gladior-pink  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                        to={coverObj.fields.slug}
                      >
                        Bekijk de Case
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
}

export default CoverBar;
