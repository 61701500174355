import loadable from "@loadable/component";
import { graphql, Link, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CoverBar from "../components/CoverBar";
import Footer from "../components/Footer";
import Header from "../components/header";
import Client from "../components/Klanten";
import SEO from "../components/seo";

const WaveBar = loadable(() => import("../components/WaveBar"));

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        display: "block",
        padding: "0",
        right: "-55px",
        transform: "translateY(-50%)",
        opacity: className.indexOf("slick-disabled") > -1 ? 0.25 : 1,
      }}
      className="h-8 w-8"
      onClick={onClick}
    >
      <button className="bg-white rounded-full shadow-md h-8 w-8 text-xl text-gladior-black hover:text-gladior-pink focus:text-gladior-pink -mr-6 focus:outline-none focus:ring">
        <span className="block" style={{ transform: "scale(1)" }}>
          ➜
        </span>
      </button>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        display: "block",
        padding: "0",
        left: "-55px",
        transform: "translateY(-50%)",
        opacity: className.indexOf("slick-disabled") > -1 ? 0.25 : 1,
      }}
      className="h-8 w-8"
      onClick={onClick}
    >
      <button className="bg-white rounded-full shadow-md h-8 w-8 text-xl text-gladior-black hover:text-gladior-pink focus:text-gladior-pink -mr-6 focus:outline-none focus:ring">
        <span className="block" style={{ transform: "scale(-1)" }}>
          ➜
        </span>
      </button>
    </div>
  );
}

function IndexPage() {
  let [circleSet, setCircleSetting] = useState(0);

  const sliderRef = useRef(null);

  let [mapVisibile, setMapVisibile] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
  });

  let [showType, setShowType] = useState("");

  let [activeSlide, setActiveSlide] = useState(0);

  //useEffect
  useEffect(() => {
    if (mapVisibile.a === true) {
      setCircleSetting(25);
      setShowType("a");
    } else if (mapVisibile.b === true && mapVisibile.a === false) {
      setCircleSetting(50);
      setShowType("b");
    } else if (mapVisibile.c === true && mapVisibile.b === false) {
      setCircleSetting(75);
      setShowType("c");
    } else if (mapVisibile.d === true && mapVisibile.c === false) {
      setCircleSetting(100);
      setShowType("d");
    }
  }, [mapVisibile]); //Pass Array as second argument

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "gladior-team.jpg" }) {
          childImageSharp {
            fluid(quality: 91, maxWidth: 2160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        heroSecondImage: file(relativePath: { eq: "rock-roll.png" }) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        mobileImage: file(relativePath: { eq: "relevant-blijven.png" }) {
          childImageSharp {
            fluid(maxWidth: 448, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        desktopImage: file(relativePath: { eq: "relevant-blijven.png" }) {
          childImageSharp {
            fluid(maxWidth: 548, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        getGrowImage: file(
          relativePath: { eq: "get-grow-performance-complete2.png" }
        ) {
          childImageSharp {
            fixed(height: 400) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
            fluid(maxWidth: 448, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        getGrowImagePerformanceMarketing: file(
          relativePath: { eq: "get-grow-performance-marketing2.png" }
        ) {
          childImageSharp {
            fixed(height: 400) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
            fluid(maxWidth: 448, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        getGrowImageDataSolutions: file(
          relativePath: { eq: "get-grow-data-solutions.png" }
        ) {
          childImageSharp {
            fixed(height: 400) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
            fluid(maxWidth: 448, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  const {
    desktop,
    heroSecondImage,
    getGrowImage,
    getGrowImagePerformanceMarketing,
    getGrowImageDataSolutions,
  } = data;

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid;

  function onChange(isVisible, progress, type) {
    setMapVisibile({ ...mapVisibile, [type]: isVisible });
  }

  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header isHomePage={true} />

      <main>
        <SEO
          title="Data Driven Digital Marketing"
          description="Data Driven Digital Marketing. Van data naar daadkracht. Relevant blijven dat vinden wij pas echt Rock&Roll. Al 20 jaar. Wij zijn Gladior!"
        />

        <BackgroundImage
          Tag="section"
          fluid={imageData}
          fadeIn={false}
          loading="eager"
          style={{
            backgroundBlendMode: "multiply",
          }}
          preserveStackingContext={true}
          opacity={0.7}
          aria-label="Fullscreen Background"
          backgroundColor={`#53575d`}
        >
          <section className="text-white  flex flex-col flex-1 justify-center w-full pb-20 pt-40  px-6 relative z-10">
            <section className=" flex flex-col justify-center content-center items-center  text-center max-w-4xl mx-auto  w-full max-w-4xl mx-auto px-4 py-8 md:p-8 ">
              {/* <h2 className="text-5xl font-serif inline-block my-8 p-3 leading-tight">
              Wij genieten van het vertalen van dromen naar doelen. <br /> Van
              data naar daadkracht.
            </h2> */}

              <div className="w-full max-w-md mx-auto">
                <Img loading="eager" fadeIn={false} fluid={sources} />
              </div>

              <div className="flex flex-row justify-center items-center flex-wrap">
                <h2 className="inline-block text-2xl text-white ">
                  Dat vinden wij pas echt
                </h2>
                <Img
                  loading="eager"
                  fadeIn={false}
                  fixed={heroSecondImage.childImageSharp.fixed}
                />
              </div>

              <div className="h-24 w-32 mt-8  flex flex-col justify-end border-t-4 border-gladior-pink my-8">
                <p className="text-center text-gladior-yellow text-2xl">
                  #HereToStay
                </p>
              </div>
            </section>
          </section>
        </BackgroundImage>

        <section className="-mt-24">
          <div className="bg-white p-3 sm:p-6 mt-24">
            <div className="container bg-white border-t-4 border-solid border-gray-600 max-w-5xl -mt-32 mx-auto p-6 sm:p-12 rounded shadow-xl">
              <div className="w-full text-center ">
                <h1 className="text-3xl sm:text-4xl md:text-5xl xl:text-4xl leading-tight  text-gladior-black font-semibold mb-3  font-display">
                  Data Driven Digital Marketing
                </h1>

                <p className="sm:text-lg md:text-xl xl:text-lg mb-4 font-light">
                  Bij Gladior doen we het al 20 jaar niet voor de awards,
                  <br />
                  of de reputatie die soms bij bureaus lijkt te horen.
                </p>

                <p className="sm:text-lg md:text-xl xl:text-lg mb-4 font-light">
                  Wij genieten van het samenspel met onze klanten.
                  <br />
                  Van het luisteren. Van het vertalen van dromen naar doelen.
                  <br />
                  Van data naar daadkracht.
                </p>

                <p className="sm:text-lg md:text-xl xl:text-lg mb-4 font-light">
                  Zo werken we als team samen in een snel veranderende wereld.
                  <br />
                  Niet aan One hit wonders, maar aan blijvers. Aan relaties.
                  <br />
                  Die zich weten aan te passen, zonder zichzelf te verliezen.
                </p>

                <p className="sm:text-lg md:text-xl xl:text-lg mb-4 font-light">
                  Relevant blijven.
                  <br />
                  Dat vinden wij pas echt Rock & Roll.
                  <br />
                  <Link
                    className="no-underline text-gladior-pink hover:underline  hover:text-gladior-pink"
                    to={"8-redenen-muziek"}
                  >
                    Waarom muziek?
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <CoverBar />

          <Client />
        </section>

        <WaveBar />

        <section className="bg-white">
          <div className="container mx-auto px-6 py-20 text-gladior-black flex flex-col  ">
            <div className="max-w-2xl mx-auto ">
              <h2 className="text-center mb-4 text-3xl sm:text-4xl md:text-5xl xl:text-4xl leading-tight  text-gladior-black font-bold font-display font-display ">
                Our instruments
              </h2>
              <h4 className="text-3xl font-bold mb-3 font-display  text-center ">
                Van resultaat, naar blijvend resultaat
              </h4>

              <p className="mt-6 leading-relaxed sm:text-lg md:text-xl xl:text-lg text-gray-700">
                Op het internet vliegen de digitale marketingmodellen je om de
                oren. Allemaal beloven ze dat je in zeven of zes stappen
                gegarandeerd snel succes behaalt...
              </p>
              <p className="text-base mb-2 font-light pt-6 ">
                Bij Gladior vinden we zeker dat je systematisch en op
                strategische wijze moet werken aan resultaat. Maar ook dat een
                model vooral werkbaar moet zijn. Dat iedereen ermee kan werken
                en ernaar kan handelen. Dat iedereen het grote plaatje ziet.
              </p>

              <p className="text-base mb-2 font-light pt-6 ">
                Daarom hebben we zelf een eenvoudig, maar vooral doeltreffend
                model opgezet. Vanuit data en inzichten toewerken naar
                duidelijke doelen en meetbare KPI’s. Dat draait om klanten
                krijgen en klanten laten groeien. Om overzicht. En dat vullen we
                natuurlijk samen met onze klanten in.
              </p>
            </div>

            <div className="bg-gladior-black px-20 py-10 hidden md:block mt-10">
              <Slider
                dots={true}
                fade={true}
                ref={sliderRef}
                afterChange={(current) => setActiveSlide(current)}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
              >
                <div>
                  <div className="flex justify-center  w-full">
                    <Img
                      fixed={getGrowImageDataSolutions.childImageSharp.fixed}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-center  w-full">
                    <Img
                      fixed={
                        getGrowImagePerformanceMarketing.childImageSharp.fixed
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center  w-full">
                    <Img fixed={getGrowImage.childImageSharp.fixed} />
                  </div>
                </div>
              </Slider>
            </div>

            <div className="flex flex-wrap mt-10 sm:mt-0  justify-between items-stretch w-full">
              <div
                onMouseOver={() => {
                  sliderRef.current.slickGoTo(0);
                }}
                className={`flex flex-1 flex-col max-w-sm shadow-lg    ${
                  activeSlide === 0 ? "md:shadow-lg" : "md:shadow-none"
                }  w-full mb-6 md:mb-0`}
              >
                <div className="bg-gladior-black block md:hidden flex justify-center p-4">
                  <Img
                    className="w-full"
                    style={{ width: "327px", height: "200px" }}
                    fluid={getGrowImageDataSolutions.childImageSharp.fluid}
                  />
                </div>
                <div className="flex-grow px-6 py-4">
                  <h4 className="text-3xl font-bold mb-3 font-display ">
                    Data solutions
                  </h4>
                  <p className=" text-base font-light ">
                    Koppelingen, data dashboards, data visualisatie/rapportage,
                    data scraping, data analyse
                  </p>
                </div>
              </div>
              <div
                onMouseOver={() => {
                  sliderRef.current.slickGoTo(1);
                }}
                className={`flex flex-1 flex-col max-w-sm shadow-lg    ${
                  activeSlide === 1 ? "md:shadow-lg" : "md:shadow-none"
                }  w-full mb-6 md:mb-0`}
              >
                <div className="bg-gladior-black block md:hidden flex justify-center p-4">
                  <Img
                    className="w-full"
                    style={{ width: "327px", height: "200px" }}
                    fluid={
                      getGrowImagePerformanceMarketing.childImageSharp.fluid
                    }
                  />
                </div>

                <div className="flex-grow px-6 py-4">
                  <h4 className="text-3xl  font-bold mb-3 font-display ">
                    Performance marketing
                  </h4>
                  <p className="text-base font-light">
                    Ondersteunend aan zowel de GET als GROW funnel, van
                    tool/kanaal gericht naar oplossingen/Performance gericht,
                    mbv platforms, tools/dashboards, campagnes en processen
                  </p>
                </div>
              </div>
              <div
                onMouseOver={() => {
                  sliderRef.current.slickGoTo(2);
                }}
                className={`flex flex-1 flex-col max-w-sm shadow-lg    ${
                  activeSlide === 2 ? "md:shadow-lg" : "md:shadow-none"
                }  w-full mb-6 md:mb-0`}
              >
                <div className="bg-gladior-black block md:hidden flex justify-center p-4">
                  <Img
                    className="w-full"
                    style={{ width: "327px", height: "200px" }}
                    fluid={getGrowImage.childImageSharp.fluid}
                  />
                </div>
                <div className="flex-grow px-6 py-4">
                  <h4 className="text-3xl  font-bold mb-3 font-display ">
                    Business consultancy
                  </h4>
                  <p className=" text-base font-light">
                    Activeren van additionele verkoop of branding kanalen in
                    zowel GET (lead generatie) als GROW (klantwaarde groei)
                    funnel
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gladior-black">
          <div className="container mx-auto px-6 py-20 text-white">
            <h2 className="text-3xl sm:text-4xl md:text-5xl xl:text-4xl leading-tight  text-white font-bold mb-3 font-display ">
              How we listen
            </h2>

            <p className="mt-6 leading-relaxed sm:text-lg md:text-xl xl:text-lg text-gray-200">
              Wij bieden 3 verschillende scans aan, om te inventariseren wat en
              waar de automatiserings- en verbeter mogelijkheden zijn met
              betrekking tot Digital Marketing.
            </p>

            <div className="flex flex-wrap  items-stretch ">
              <div className="flex flex-col max-w-sm pr-4 py-2">
                <div className="py-4 flex-grow">
                  <h4 className="text-2xl text-white font-bold mb-3 font-display ">
                    Web Presence Performance
                  </h4>
                  <p className="text-white text-base font-light">
                    Performance van web omgeving (landingspagina’s), SEO
                    inrichting, etc. Waarbij wordt gekeken of de doelstellingen
                    van de organisatie wel worden afgedekt door de technische
                    aspecten en inrichting van de website, hoe het sales proces
                    wordt ondersteund door call to actions en hoe optimaal de
                    organische vindbaarheid en autoriteit van de organisatie is.
                  </p>
                </div>

                <div className="py-4 mt-3">
                  <Link
                    className=" mt-4  h-12 sm:mt-0 sm:h-auto  px-6 py-3 font-semibold leading-snug bg-white text-gladior-black uppercase tracking-wide rounded-lg shadow-md focus:outline-none focus:ring hover:bg-gladior-pink"
                    to={"/scans"}
                  >
                    Meer informatie
                  </Link>
                </div>
              </div>
              <div className="flex flex-col max-w-sm md:px-4 py-2 ">
                <div className="py-4 flex-grow">
                  <h4 className="text-2xl text-white font-bold mb-3 font-display ">
                    Digital Marketing
                  </h4>
                  <p className="text-white text-base font-light">
                    Waarbij wordt gekeken of de ingezette kanalen en campagne
                    inrichting wel optimaal plaatvinden, mbt SEA (zoekmachine
                    marketing), GDN (Google display banner advertenties),
                    Display Advertenties, Social Media, Retargeting
                    zoekmachine/display. Daarnaast wordt gekeken naar conversie
                    optimalisatie: met welke funnel opbouw en content wordt de
                    doelgroep benaderd.
                  </p>
                </div>

                <div className="py-4 mt-3">
                  <Link
                    className=" mt-4  h-12 sm:mt-0 sm:h-auto  px-6 py-3 font-semibold leading-snug bg-white text-gladior-black uppercase tracking-wide rounded-lg shadow-md focus:outline-none focus:ring hover:bg-gladior-pink"
                    to={"/scans"}
                  >
                    Meer informatie
                  </Link>
                </div>
              </div>
              <div className="flex flex-col max-w-sm md:px-4 py-2 ">
                <div className="py-4 flex-grow">
                  <h4 className="text-2xl text-white font-bold mb-3 font-display ">
                    Contactstrategie
                  </h4>
                  <p className="text-white text-base font-light">
                    Analyse van hoe klantbehoud en klantwaarde groei worden
                    ondersteund dmv Digital Marketing: Marketing
                    Automation/Email Marketing, Personalisatie, Service Calls,
                    etc.
                  </p>
                </div>

                <div className="py-4 mt-3">
                  <Link
                    className=" mt-4  h-12 sm:mt-0 sm:h-auto  px-6 py-3 font-semibold leading-snug bg-white text-gladior-black uppercase tracking-wide rounded-lg shadow-md focus:outline-none focus:ring hover:bg-gladior-pink"
                    to={"/scans"}
                  >
                    Meer informatie
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer showPartners={true} />
      </main>
    </div>
  );
}

export default IndexPage;
