import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Slider from "react-slick";

function LogoSlider({}) {
  const data = useStaticQuery(
    graphql`
      query {
        allClientYaml {
          edges {
            node {
              description
              title
              logo {
                childImageSharp {
                  fixed(width: 120) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const { allClientYaml } = data;

  const settings = {
    className: "ClientReel",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          centerMode: true,
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="my-8  px-0 md:px-8 container mx-auto">
      <Slider {...settings}>
        {allClientYaml.edges.map((edge) => {
          let logoObj = edge.node;
          return (
            <div key={logoObj.title} className="logoContainer">
              <Img
                fixed={logoObj.logo.childImageSharp.fixed}
                alt={logoObj.title}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default LogoSlider;
